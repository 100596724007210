import { useEffect, useState } from "react";
import { Box, Paper, Stack, Typography, CircularProgress } from "@mui/material";
import { Radar } from "react-chartjs-2";
import { useAuthState } from "@bonbon/data-access-auth";
import { getUserCognitionData, UserCognitionData } from "../lib/api/user";
import { useSnackbar } from "../lib/snackbar";

export const DashboardPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [userCognitionData, setUserCognitionData] = useState<UserCognitionData>();

  const { idToken, currentUser } = useAuthState();

  const showSnackbar = useSnackbar();

  useEffect(() => {
    if (!idToken || !currentUser) {
      console.error("Not logged in");
      showSnackbar("Application error");
      return;
    }

    setLoading(true);

    (async () => {
      let data: UserCognitionData;
      try {
        data = await getUserCognitionData(idToken, currentUser.id);
      } catch (e) {
        console.error(e);
        setLoading(false);
        showSnackbar("Application error");
        return;
      }

      setUserCognitionData(data);
      setLoading(false);
    })();
  }, [idToken, currentUser, showSnackbar]);

  if (loading || !userCognitionData) {
    return (
      <Box textAlign="center" pt={8}>
        <CircularProgress />
      </Box>
    )
  }

  const options = {
    scales: {
      r: {
        beginAtZero: true,
        suggestedMax: 6,
        ticks: {
          stepSize: 1,
          callback: (value: string | number) => {
            return ["", "F", "E", "D", "C", "B", "A"][value as number];
          },
        }
      },
    },
  };

  const data = {
    labels: [
      "自分の記憶力に対する自信",
      "実際の記憶力の精度",
      "抽象的な思考をする能力",
      "細かい差を見極める能力",
      "集中を途切れさせない能力",
    ],
    datasets: [
      {
        data: [userCognitionData.A, userCognitionData.B, userCognitionData.C, userCognitionData.D, userCognitionData.E].map(value => {
          if (value <= -2.0) return 1;
          if (value <= -1.0) return 2;
          if (value <= 0.0) return 3;
          if (value <= 1.0) return 4;
          if (value <= 2.0) return 5;
          return 6;
        }),
        fill: true,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgb(255, 99, 132, 0.5)",
      },
    ],
  };

  return (
    <>
      <Stack mt={2}>
        <Box component={Paper} p={2}>
          <Radar options={options} data={data} />
        </Box>
      </Stack>
    </>
  );
};
