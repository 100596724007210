import React from "react";
import { useNavigate, useLocation, Location, Outlet } from "react-router-dom";
import { BonbonIDProviderContainer } from "@bonbon/data-access-auth";
import {
  AuthRoute,
  BonbonIdFeatureContainer,
  SignIn,
  SignUp,
  ResetPassword,
  EmailVerification,
  RegisterProfile,
  AccountSetting,
  PasswordSetting,
} from "@bonbon/feature-auth";
import "@bonbon/feature-auth/bonbon-id-feature-auth.umd.css";
import "@bonbon/feature-auth/bonbon-id-feature-auth.esm.css";

const bonbonApiUrl = process.env.REACT_APP_BONBON_API_GATEWAY || "";
const contactUrl = process.env.REACT_APP_CONTACT_URL || "";
const privacyPolicyUrl = process.env.REACT_APP_PRIVACY_POLICY_URL || "";
const termOfUseUrl = process.env.REACT_APP_TERM_OF_USE_URL || "";
const manabonUrl = process.env.REACT_APP_MANABON_URL || "";
const tsucomUrl = process.env.REACT_APP_TSUCOM_URL || "";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "",
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "",
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL || "",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "",
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "",
  appId: process.env.REACT_APP_FIREBASE_APP_ID || "",
  measurementId: "",
};

const homePath = "/";

const getContinueUrl = (location: Location) =>
  new URLSearchParams(location.search).get("continueUrl") || undefined;

const createAuthComponent = (
  Component: React.FC<{
    onRedirect: () => void;
    onClickLink: (path: string) => void;
  }>
) => {
  return () => {
    const navigate = useNavigate();
    const location = useLocation();
    const continueUrl = getContinueUrl(location);

    const authProps = {
      onRedirect: () => {
        navigate(continueUrl || homePath);
      },
      onClickLink: (path: string) => {
        navigate(
          continueUrl
            ? `${path}?continueUrl=${encodeURIComponent(continueUrl)}`
            : path
        );
      },
    };

    return <Component {...authProps} />;
  };
};

export const SignInPage = createAuthComponent(SignIn);
export const SignUpPage = createAuthComponent(SignUp);
export const ResetPasswordPage = createAuthComponent(({ onClickLink }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const onRedirect = () => {
    const continueUrl = getContinueUrl(location);
    navigate(
      continueUrl
        ? `/signin?continueUrl=${encodeURIComponent(continueUrl)}`
        : "/signin"
    );
  };
  return <ResetPassword onRedirect={onRedirect} onClickLink={onClickLink} />;
});
export const EmailVerificationPage = createAuthComponent(({ onRedirect }) => {
  const location = useLocation();
  const continueUrl = getContinueUrl(location);
  return (
    <EmailVerification
      redirectPath={continueUrl || homePath}
      onRedirect={onRedirect}
    />
  );
});
export const RegisterProfilePage = createAuthComponent(RegisterProfile);
export const AccountSettingPage = createAuthComponent(AccountSetting);
export const PasswordSettingPage = createAuthComponent(PasswordSetting);

type AuthProps = {
  publicRoutes?: RegExp[];
  privateRoutes?: RegExp[];
};

export const Auth = ({ publicRoutes, privateRoutes }: AuthProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <BonbonIdFeatureContainer
      homePath={homePath}
      signInPath={"/signin"}
      signUpPath={"/signup"}
      resetPasswordPath={"/reset-password"}
      emailVerificationPath={"/email-verification"}
      registerProfilePath={"/register-profile"}
      accountSettingPath={"/account/account-setting"}
      passwordSettingPath={"/account/password-setting"}
      contactUrl={contactUrl}
      privacyPolicyUrl={privacyPolicyUrl}
      termOfUseUrl={termOfUseUrl}
      manabonUrl={manabonUrl}
      tsucomUrl={tsucomUrl}
    >
      <BonbonIDProviderContainer
        bonbonAPIURL={bonbonApiUrl}
        firebaseConfig={firebaseConfig}
      >
        <AuthRoute
          restrictedRoutes={[/^\/signup$/, /^\/signin$/, /^\/reset-password$/]}
          publicRoutes={publicRoutes}
          privateRoutes={[
            /^\/email-verification$/,
            /^\/register-profile$/,
            /^\/account\/account-setting$/,
            /^\/account\/password-setting$/,
            ...(privateRoutes || []),
          ]}
          routedPath={location.pathname}
          searchString={location.search}
          continueUrl={getContinueUrl(location)}
          isReady={true}
          onReplace={(path) => navigate(path)}
        >
          <Outlet />
        </AuthRoute>
      </BonbonIDProviderContainer>
    </BonbonIdFeatureContainer>
  );
};
