import { useState, useEffect } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { Box, Typography, CircularProgress } from "@mui/material";
import { useAuthState } from "@bonbon/data-access-auth";
import { getUser, createUser } from "../lib/api/user";
import { allocateAccessKeyToUser } from "../lib/api/access_key";
import { NotFoundError } from "../lib/api/api";
import { useSnackbar } from "../lib/snackbar";

export const RegisterPotionKeyPage = () => {
  const [succeeded, setSucceeded] = useState<boolean>();

  const { idToken, currentUser } = useAuthState();

  const showSnackbar = useSnackbar();

  const location = useLocation();

  const accessKeyId = new URLSearchParams(location.search).get("accessKeyId");
  const expiredAt = parseInt(
    new URLSearchParams(location.search).get("expiredAt") ?? "",
    10
  );
  const signature = new URLSearchParams(location.search).get("signature");

  useEffect(() => {
    if (!idToken || !currentUser || !accessKeyId || !signature) {
      console.error("Not logged in or invalid query parameters");
      setSucceeded(false);
      showSnackbar("Application error");
      return;
    }

    (async () => {
      let user;
      try {
        user = await getUser(idToken, currentUser.id);
      } catch (e) {
        if (!(e instanceof NotFoundError)) {
          console.error(e);
          setSucceeded(false);
          showSnackbar("Application error");
          return;
        }
      }

      if (!user) {
        try {
          user = await createUser(idToken);
        } catch (e) {
          console.error(e);
          setSucceeded(false);
          showSnackbar("Application error");
          return;
        }
      }

      try {
        await allocateAccessKeyToUser(
          idToken,
          accessKeyId,
          expiredAt,
          signature,
          user.id
        );
      } catch (e) {
        console.error(e);
        setSucceeded(false);
        showSnackbar("Application error");
        return;
      }

      setSucceeded(true);
    })();
  }, [idToken, currentUser, accessKeyId, expiredAt, signature, showSnackbar]);

  if (succeeded === undefined) {
    return (
      <Box width="100%" textAlign="center" mt={8}>
        <CircularProgress />
      </Box>
    );
  }

  if (succeeded) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <Box>
        <Typography>Failed to register access key.</Typography>
      </Box>
    </>
  );
};
