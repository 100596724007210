import { callPotionApi } from "./api";

export const allocateAccessKeyToUser = async (
  authToken: string,
  accessKeyId: string,
  expiredAt: number,
  signature: string,
  userId: string
): Promise<void> => {
  return await callPotionApi(
    authToken,
    `/potion/accessKeys/${accessKeyId}/allocate`,
    "POST",
    {
      userId,
      expiredAt,
      signature,
    }
  );
};
