import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  Chart as ChartJS,
  PointElement,
  LineElement,
  RadialLinearScale,
  Filler,
} from "chart.js";
import {
  Auth,
  SignInPage,
  SignUpPage,
  ResetPasswordPage,
  EmailVerificationPage,
  RegisterProfilePage,
  AccountSettingPage,
  PasswordSettingPage,
} from "./components/Auth";
import { Layout } from "./components/Layout";
import { RegisterPotionKeyPage } from "./pages/RegisterPotionKeyPage";
import { DashboardPage } from "./pages/DashboardPage";

ChartJS.register(PointElement, LineElement, RadialLinearScale, Filler);

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Auth
                privateRoutes={[
                  /^\/$/,
                  /^\/register-potion-key$/,
                  /^\/dashboard$/,
                ]}
              />
            }
          >
            <Route path="/signin" element={<SignInPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route
              path="/email-verification"
              element={<EmailVerificationPage />}
            />
            <Route path="/register-profile" element={<RegisterProfilePage />} />
            <Route
              path="/account/account-setting"
              element={<AccountSettingPage />}
            />
            <Route
              path="/account/password-setting"
              element={<PasswordSettingPage />}
            />

            <Route path="" element={<Layout />}>
              <Route index element={<Navigate to="/dashboard" />} />
              <Route
                path="/register-potion-key"
                element={<RegisterPotionKeyPage />}
              />
              <Route path="/dashboard" element={<DashboardPage />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
