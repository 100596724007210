export class NotFoundError extends Error {}

const callApi = async (
  host: string,
  authToken: string | null,
  path: string,
  method: string,
  body?: any
) => {
  const resp = await fetch(`${host}${path}`, {
    method: method,
    ...(body && { body: JSON.stringify(body) }),
    headers: {
      "Content-Type": "application/json",
      ...(authToken && { Authorization: `Bearer ${authToken}` }),
    },
  });

  if (!resp.ok) {
    const text = await resp.text();
    console.error(text);

    if (resp.status === 404) {
      throw new NotFoundError(text);
    }

    throw new Error(`API call failed: status=${resp.status} body=${text}`);
  }

  return await resp.json();
};

export const callBonBonApi = async (
  authToken: string | null,
  path: string,
  method: string,
  body?: any
) => {
  return await callApi(
    process.env.REACT_APP_BONBON_API_GATEWAY ?? "",
    authToken,
    path,
    method,
    body
  );
};

export const callPotionApi = async (
  authToken: string | null,
  path: string,
  method: string,
  body?: any
) => {
  return await callApi(
    process.env.REACT_APP_POTION_API_GATEWAY ?? "",
    authToken,
    path,
    method,
    body
  );
};
