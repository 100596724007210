import { callPotionApi, callBonBonApi } from "./api";

export type User = {
  id: string;
  firebaseUid: string;
};

export const createUser = async (authToken: string): Promise<User> => {
  return await callPotionApi(authToken, `/potion/users`, "POST", {});
};

export const getUser = async (
  authToken: string,
  userId: string
): Promise<User> => {
  return await callPotionApi(authToken, `/potion/users/${userId}`, "GET");
};

export type UserProfile = {
  name: string;
  display_name: string;
  email: string;
  image_url: string;
};

export const getUserProfile = async (
  authToken: string,
  userId: string
): Promise<UserProfile> => {
  return await callBonBonApi(
    authToken,
    `/porous/users/${userId}/profile`,
    "GET"
  );
};

export type UserCognitionData = {
  userId: string
  dataDate: string
  A: number
  B: number
  C: number
  D: number
  E: number
}

export const getUserCognitionData = async (authToken: string, userId: string): Promise<UserCognitionData> => {
  return await callPotionApi(authToken, `/potion/users/${userId}/cognitionData`, "GET");
}
