import { useState, useCallback } from "react";
import { Link as RouterLink, Outlet } from "react-router-dom";
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  Snackbar,
  Alert,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  CssBaseline,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAuthState, useAuthDispatch } from "@bonbon/data-access-auth";
import { SnackbarContext } from "../lib/snackbar";

export const Layout = () => {
  const [snackbar, setSnackbar] = useState<{ message: string } | null>(null);

  const showSnackbar = useCallback((message: string) => {
    setSnackbar({ message });
  }, []);

  const { currentUser } = useAuthState();
  const { handleSignOut } = useAuthDispatch();

  const [userMenuAnchorElem, setUserMenuAnchorElem] =
    useState<null | HTMLElement>(null);

  return (
    <>
      <CssBaseline />

      {snackbar && (
        <Snackbar
          open={!!snackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setSnackbar(null)}
        >
          <Alert severity="error" onClose={() => setSnackbar(null)}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      )}

      <SnackbarContext.Provider value={showSnackbar}>
        <Box bgcolor="whitesmoke" minHeight="100vh">
          <AppBar position="sticky">
            <Toolbar>
              <Box
                maxWidth="sm"
                mx="auto"
                width="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h1" fontSize="1.5rem">
                  Potion
                </Typography>

                {currentUser && (
                  <div>
                    <IconButton
                      size="large"
                      color="inherit"
                      onClick={(e) => setUserMenuAnchorElem(e.currentTarget)}
                    >
                      {currentUser.image_url ? (
                        <Avatar src={currentUser.image_url} />
                      ) : (
                        <Avatar
                          sx={(theme) => ({
                            bgcolor: theme.palette.secondary.light,
                          })}
                        >
                          {(currentUser.display_name[0] ?? "").toUpperCase()}
                        </Avatar>
                      )}
                    </IconButton>
                    <Menu
                      anchorEl={userMenuAnchorElem}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={!!userMenuAnchorElem}
                      onClose={() => setUserMenuAnchorElem(null)}
                    >
                      <MenuItem
                        divider
                        onClick={() => setUserMenuAnchorElem(null)}
                        component={RouterLink}
                        to="/account/account-setting"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <ListItemIcon>
                          <AccountCircle />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography variant="subtitle1">
                            {currentUser.display_name}
                          </Typography>
                          <Typography variant="subtitle2">
                            {currentUser.email}
                          </Typography>
                        </ListItemText>
                      </MenuItem>
                      <MenuItem onClick={() => handleSignOut()}>
                        <ListItemIcon>
                          <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText>Logout</ListItemText>
                      </MenuItem>
                    </Menu>
                  </div>
                )}
              </Box>
            </Toolbar>
          </AppBar>

          <Box maxWidth="sm" mx="auto" px={2}>
            <Outlet />
          </Box>
        </Box>
      </SnackbarContext.Provider>
    </>
  );
};
